/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react'
import styled, { withTheme } from 'styled-components'

import { useMatch, useLocation } from '@reach/router'
import { Link } from 'gatsby'

import { mediaQuery } from '@zendeskgarden/react-theming'
import { Tag } from '@zendeskgarden/react-tags'
import { Tiles } from '@zendeskgarden/react-forms'
import { Grid, Row, Col } from '@zendeskgarden/react-grid'

import {
  ensureLeadingSlash,
  ensureObject,
  ensureArray,
  arrayNotEmpty,
  stringNotEmpty,
  compareString,
  nextRandom,
  titleCase,
} from '@agnostack/lib-core'
import { groupArray } from '@agnostack/lib-utils-js'

import { getTitle } from '../gatsby'
import {
  SEO,
  ListingFooter,
  Heading,
  StyledMarkdown,
  SelectableAnchor,
} from '../components/atoms'
import TemplatedPage from '../components/molecules/TemplatedPage'
import PageFragment from '../components/molecules/PageFragment'

const IconImage = styled.img`
  height: auto;
  width: auto;
  max-height: 5rem;
  max-width: 100%;

  ${({ theme }) => mediaQuery('down', 'xs', theme)} {
    max-width: 70%;
  }
`

const SelectedPartner = styled.div`
  margin-top: 3em;
  min-height: 30vh;
`

const StyledTile = styled(Tiles.Tile)`
  min-height: 7rem;
  display: flex;
  position:relative;
  flex-direction: column;
  justify-content: center;

  && {
    &&[data-garden-selected='true'] {
      color: inherit;
      border-color: inherit;
      background-color: ${({ theme }) => theme.colors.shadow2};

      &:hover {
        background-color: ${({ theme }) => theme.colors.shadow3};
      }
    }
  }
`

const PartnerTag = styled(Tag).attrs(() => ({
  isPill: true,
  size: 'small',
  hue: 'red',
}))`
  position: absolute;
  top: .3rem;
  right: .25rem;
  text-transform: uppercase;
`

const HeadingTag = styled(Tag).attrs(() => ({
  isPill: true,
  size: 'large',
  hue: 'teal',
}))``

const StyledRow = styled(Row)`
  &:not(:first-of-type) {
    margin-top: ${({ theme }) => theme.space.sm};
  }
`

const StyledCol = styled(Col)`
  &:not(:first-of-type) {
    ${({ theme }) => mediaQuery('down', 'xs', theme)} {
      margin-top: ${({ theme }) => theme.space.sm};
    }
  }
`

const ContentBody = styled.div`
  display: flex;
  justify-content: space-between;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}) {
    flex-direction: column;
  }
`

const FragmentBody = styled.div`
  flex-grow: 1;
`

const LinkGroups = styled.div`
  min-width: 20rem;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
    flex: auto;
    max-width: 25vw;
  }
`

const LinkGroup = styled.div`
  margin-bottom: 2em;
`

const GroupItem = styled.li`
  margin: .5em;
`

const StyledLink = styled(Link).attrs(() => ({
  partiallyActive: true,
  activeClassName: 'active',
}))`
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.active {
    ${({ theme }) => `
      font-weight: ${theme?.fontWeights?.bold};
    `}

    &:hover {
      cursor: default;
      text-decoration: none;
    }
  }
`

const COLUMNS = 4

export default withTheme(({
  pageContext,
  pageContext: {
    partnerCategory,
    partnerCategories,
    fragments,
    node: {
      frontmatter: {
        title: pageTitle,
        canonicalPath,
        grouping,
        subtitle,
        category,
        imagePath,
        path,
        keywords,
        description,
        template,
        siteMetadata: {
          tagline,
        } = {},
      } = {},
    } = {},
  } = {},
}) => {
  const [selectedPartnerId, setSelectedPartnerId] = useState()
  const { pathname } = useLocation()

  // TODO: add to other templates
  const fragment = useMemo(() => (
    ensureArray(fragments).find(({
      node: {
        frontmatter: {
          match,
        } = {},
      } = {},
    }) => useMatch(match))
  ), [fragments])

  const { browserTitle, browserDescription, browserKeywords, title, headline, pageTagline } = useMemo(() => {
    // eslint-disable-next-line no-underscore-dangle
    const _headline = getTitle({ context: pageContext })
    // eslint-disable-next-line no-underscore-dangle
    const _title = ((_headline === titleCase(template)) && tagline)
      ? `${[_headline, grouping].filter(stringNotEmpty).join(' ')}: ${tagline}`
      : [_headline, grouping].filter(stringNotEmpty).join(' ')

    const _pageTagline = (
      subtitle || (
        (_headline === pageTitle)
          ? grouping || category || pageTitle
          : pageTitle
      )
    )

    const inlineTitle = [_title, _pageTagline].filter(stringNotEmpty).join(' ')
    const { _browserTitle, _browserKeywords, _browserDescription } = (!_pageTagline?.includes('Integrations') && (_headline !== 'All') && (_pageTagline !== 'Zendesk'))
      ? {
        _browserTitle: `${[_pageTagline, 'Zendesk'].join(' and ')}: ${_title.replace(':', '')}`,
        _browserKeywords: [`Zendesk ${_pageTagline} Integration`, `Zendesk + ${_pageTagline}`, _pageTagline, `${_pageTagline} agnoStack Integration`, inlineTitle, _title].join(', '),
        _browserDescription: [`Integrate ${_pageTagline} with Zendesk. ${_title}`, description].filter(stringNotEmpty).join(' '),
      } : {
        _browserTitle: inlineTitle,
        _browserKeywords: keywords.replace(', All,', ',').replace('partners, integrations,', 'Zendesk eCommerce partners, Zendesk integrations,'),
        _browserDescription: description,
      }

    return {
      title: inlineTitle,
      browserTitle: _browserTitle,
      browserKeywords: _browserKeywords,
      browserDescription: _browserDescription,
      pageTagline: _pageTagline,
      headline: _title,
    }
  }, [pageContext, description, keywords, category, grouping, pageTitle, subtitle, tagline, template])

  const { partners, groupedPartners } = useMemo(() => {
    const partnerValues = Object.values(ensureObject(partnerCategory?.data))
      .sort(({ titel: title1 }, { title: title2 }) => compareString(title1, title2))

    return {
      partners: partnerValues,
      groupedPartners: groupArray(partnerValues, COLUMNS),
    }
  }, [partnerCategory])

  const selectedPartner = useMemo(() => (
    partners?.find(({ id }) => (id === selectedPartnerId))
  ), [partners, selectedPartnerId])

  useEffect(() => {
    if (arrayNotEmpty(partners)) {
      const partner = partners.find(({ path: partnerPath }) => (pathname === partnerPath)) ?? partners[nextRandom(partners.length - 1, 0)]

      setSelectedPartnerId(partner?.id)
    }
  }, [partners, pathname])

  const children = (
    <ContentBody>
      <LinkGroups>
        <LinkGroup>
          <div>
            <HeadingTag>
              Integrations & Partners
            </HeadingTag>
          </div>
          <ul>
            {ensureArray(partnerCategories).map(({
              title: partnerCategoryTitle,
              subtitle: partnerCategorySubtitle,
              path: partnerCategoryPath,
            }, partnerCategoryIndex) => (
              <GroupItem key={`link-partnercategory-${partnerCategoryIndex}`}>
                <StyledLink to={ensureLeadingSlash(partnerCategoryPath)}>
                  {[partnerCategoryTitle, partnerCategorySubtitle].filter(stringNotEmpty).join(' ')}
                </StyledLink>
                {(partnerCategoryPath === partnerCategory?.path) && (
                  <ul>
                    {partners.map(({ name: partnerName, path: partnerPath }, partnerIndex) => (
                      <GroupItem key={`link-partner-${partnerIndex}`}>
                        <StyledLink to={ensureLeadingSlash(partnerPath)}>
                          {partnerName}
                        </StyledLink>
                      </GroupItem>
                    ))}
                  </ul>
                )}
              </GroupItem>
            ))}
          </ul>
        </LinkGroup>
      </LinkGroups>
      <FragmentBody>
        <Heading tag="2">{title}</Heading>
        {fragment && (
          <PageFragment data={fragment} fragments={fragments} />
        )}
        <Grid>
          <Tiles
            name={title}
            value={selectedPartnerId}
            onChange={({ target: { value } = {} }) => setSelectedPartnerId(value)}
          >
            {groupedPartners.map((partnerGroup, groupIndex) => (
              <StyledRow key={`group-${groupIndex}`}>
                {partnerGroup.map((partner, partnerIndex) => (
                  <StyledCol key={`partner-${groupIndex}-${partnerIndex}`} sm={12 / COLUMNS}>
                    <StyledTile value={partner.id}>
                      {/* // TODO: add support for multiple badges */}
                      {(stringNotEmpty(partner.badges?.[0]) && (partner.badges[0] !== headline)) && (
                        <PartnerTag>
                          {partner.badges[0]}
                        </PartnerTag>
                      )}
                      {partner.image && (
                        <Tiles.Icon>
                          <IconImage src={partner.image} alt={partner.name} />
                        </Tiles.Icon>
                      )}
                      <Tiles.Label>
                        {partner.name}
                      </Tiles.Label>
                    </StyledTile>
                  </StyledCol>
                ))}
              </StyledRow>
            ))}
          </Tiles>
        </Grid>
        {selectedPartner && (
          <SelectedPartner>
            <Heading tag="4">
              <span>agnoStack + </span>
              <span>{selectedPartner?.name}</span>
              <span> Integration</span>
            </Heading>
            <StyledMarkdown>
              {selectedPartner?.description ?? ''}
            </StyledMarkdown>
            <br />
            <SelectableAnchor href={selectedPartner?.link} isExternal>
              {selectedPartner?.link}
            </SelectableAnchor>
          </SelectedPartner>
        )}
        <ListingFooter />
      </FragmentBody>
    </ContentBody>
  )

  return (
    <>
      <SEO
        title={browserTitle}
        description={browserDescription}
        keywords={browserKeywords}
        path={path}
        canonicalPath={canonicalPath}
        imagePath={imagePath}
      />
      <TemplatedPage
        format="medium"
        headline={headline}
        tagline={pageTagline}
        data={{ children }}
      />
    </>
  )
})
